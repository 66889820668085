<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Reseller Order List" page-icon="mdi-briefcase" :back-button="backButton">
    <g-list-page
        :columns="columns"
        :headers="headers"
        sort="orderId"
        :descending="true"
        stateendpoint="salesCounter.salesCounters"
        :actions="actions"
        :show-default-action-buttons="false"
        :action-buttons="actionButton"
        :show-total="true"
        :show-selected="true"
        list-group="sales-counters"
        @loadData="loadData"
        @editItemNewPage="editItemNewPage"
        @deleteItem="deleteLinksItem"
        @destroyItem="destroyLinksItem"
        @restoreItem="restoreItem"
        @removePostItem="removePostItem"
        @deleteWithoutItem="deleteWithoutItem"
        @counterLinkHandler="counterLinkHandler"
        @salesDetailsHandler="salesDetailsHandler"
        @republishHandler="republishHandler"
        @liveLinkReplacementHandler="liveLinkReplacementHandler"
        @onChangedPrepaymentStatus="onChangedPrepaymentStatus"
        @selectedItemHandler="selectedItemHandler"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(invoice id, email)"
                @applySearch="handleSearch"
            />
          </v-col>
        </v-row>
        <v-row>
          <g-date-selector
              class="ma-2 ml-6"
              @onChangeDateHandler="calenderHandler"
          />
          <v-select
              dense
              outlined
              class="ma-2 ml-6"
              :items="billingTypes"
              v-model="currentPaging.billingType"
              label="Select a billing type"
              style="max-width: 250px"
              clearable
              @change="loadData({isPagingReset: true})"
          />
          <v-select
              dense
              outlined
              class="ma-2 ml-6"
              :items="payStatusItems"
              v-model="currentPaging.paymentStatus"
              label="Select a payment status"
              style="max-width: 250px"
              clearable
              @change="loadData({isPagingReset: true})"
          />
          <g-p-auto-complete
              :dense="true"
              :outlined="true"
              :status=true
              :validation="false"
              apiPath="get-admins"
              search-label="Select user"
              @clearSearchResult="userHandler"
              @selectSearchResult="userHandler"
              class="ma-2 ml-6"
              style="max-width: 250px"
          />
          <g-p-auto-complete
              :dense="true"
              :outlined="true"
              :status=true
              :validation="false"
              apiPath="order-emails"
              search-label="Select order from"
              @clearSearchResult="orderFromHandler"
              @selectSearchResult="orderFromHandler"
              class="ma-2 ml-6"
              style="max-width: 250px"
          />
          <v-menu transition="slide-y-transition" offset-y v-if="isAdmin || checkAccess('orderBulkActions', 'actions')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="ma-2 ml-6"
                  v-bind="attrs"
                  v-on="on"
                  text
              >
                Actions <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, key) in bulkActions" :key="key" @click="item.clickHandler">
                <v-list-item-action>
                  <v-icon :color="item.color || 'primary' ">{{item.icon}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{item.text}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

        </v-row>
      </template>
    </g-list-page>
    <component
        is="CounterLinkPopup"
        ref="component"
        :item="editedItem"
        :show="showPopup"
        @closePopupHandler="closePopupHandler"
        @onPopupShow="openPopUp"
    />
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
    <base-dialog
        title="Duplicated Links Log Details"
        icon="mdi-close"
        :dialog="dialog"
        :progress="progress"
        @closeDialogHandler="closeDialogHandler()"
    >
      <v-container>
        <v-card v-for="(item, key) in duplicatedLinks" :key="key" class="mb-5">
          <v-card-title class="text-h5">
            Order ID: {{item.orderId}}
          </v-card-title>
          <v-card-subtitle>{{item.email}} <br> <strong>{{item.billingType}}</strong></v-card-subtitle>
          <v-card-text>{{item.liveLink}}</v-card-text>
        </v-card>
      </v-container>

    </base-dialog>
  </g-card>
</template>
<script>
import ListMixins from "../../components/mixins/list/List";
import GCard from "../../components/GCard";
import GListPage from "../../components/list/GListPage";
import PageOverlay from "../../components/PageOverlay";
import GPSearch from "../../components/GPSearch";
import GDateSelector from "../../components/calendar/GDateSelector";
import GPAutoComplete from "../../components/GPAutoComplete";
import ConfirmPopup from "../../components/popups/ConfirmPopup";
import CounterLinkPopup from "../../components/popups/CounterLinkPopup";
import moment from "moment/moment";
import BaseDialog from "../../components/dialog/BaseDialog";
import IsAuthenticated from "../../middleware/IsAuthenticated";

export default {
  name: 'SalesCounters',
  metaInfo: {
    title: 'shantoitfirm.com',
    titleTemplate: 'Admin Dashboard - Reseller Order List | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    BaseDialog,
    ConfirmPopup,
    GPAutoComplete,
    GDateSelector,
    GPSearch,
    PageOverlay,
    GListPage,
    GCard,
    CounterLinkPopup
  },
  mixins: [ListMixins, IsAuthenticated],
  data() {
    return {
      dialog: false,
      progress: false,
      duplicatedLinks: [],
      selectedItems: [],
      headers: [
        {
          text: 'Order ID',
          value: 'orderId'
        },
        {
          text: 'Billing Type',
          value: 'billingType'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Total Amount',
          value: 'totalAmount'
        },
        {
          text: 'Status',
          value: 'paymentStatus',
          align: "center"
        },
        {
          text: 'Is Prepaid?',
          value: 'isPrepayment',
          align: "center"
        },
        {
          text: 'Order From',
          value: 'contactMail',
          align: "center"
        },
        {
          text: 'Order Date',
          value: 'date',
          align: "center"
        },
        {
          text: 'Updated Date',
          value: 'updatedAt',
          align: "center"
        },
        {
          text: 'Paid Date',
          value: 'paymentDate',
          align: "center"
        },
        {
          text: "User",
          value: "user.name",
          align: "center"
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      columns: [
        {
          name: "totalAmount",
          callback: "getMoneySign"
        },
        {
          name: "date",
          callback: "getDate"
        },
        {
          name: "paymentDate",
          callback: "getDate"
        },
        {
          name: "updatedAt",
          callback: "getDate"
        },
      ],
      actions: {
        load: 'salesCounter/load',
        update: 'salesCounter/store',
        removePost: 'salesCounter/removePosts',
        remove: 'salesCounter/remove',
        destroy: 'salesCounter/destroy',
        restore: 'salesCounter/restore',
        republish: 'salesCounter/republish',
        deleteWithoutPost: 'salesCounter/destroyWithoutPost',
        removeBulkPostItem: 'salesCounter/removeBulkPostItem',
        deleteBulkPostItem: 'salesCounter/deleteBulkPostItem',
      },
      actionButton: [
        {
          category: 'edit',
          icon: 'mdi-pencil',
          color: '',
          text: 'Edit',
          clickHandler: 'editItemNewPage'
        },
        {
          category: 'remove',
          icon: 'mdi-basket-remove',
          color: 'error',
          text: 'Remove',
          clickHandler: 'removePostItem'
        },
        {
          category: 'delete',
          icon: 'mdi-delete',
          color: 'error',
          text: 'Delete',
          clickHandler: 'deleteItem'
        },
        {
          category: 'deletePermanent',
          icon: 'mdi-delete-forever',
          color: 'error',
          text: 'Delete Forever',
          clickHandler: 'destroyItem'
        },
        {
          category: 'deleteWithoutPost',
          icon: 'mdi-delete-forever',
          color: 'error',
          text: 'Delete without Post',
          clickHandler: 'deleteWithoutItem'
        },
        {
          category: 'restore',
          icon: 'mdi-backup-restore',
          color: '',
          text: 'Restore',
          clickHandler: 'restoreItem'
        },
        {
          category: 'link',
          icon: 'mdi-eye',
          color: '',
          text: 'View Live Link',
          clickHandler: 'counterLinkHandler'
        },
        {
          category: 'details',
          icon: 'mdi-details',
          color: '',
          text: 'View Details',
          clickHandler: 'salesDetailsHandler'
        },
        {
          category: 'republish',
          icon: 'mdi-publish',
          color: '',
          text: 'Article Republish',
          clickHandler: 'republishHandler'
        },
        {
          category: 'replacement',
          icon: 'mdi-file-replace-outline',
          color: '',
          text: 'Live Link Replacement',
          clickHandler: 'liveLinkReplacementHandler'
        },
        {
          category: 'prepaid',
          icon: 'mdi-stack-exchange',
          color: '',
          text: 'Change Prepayment Status',
          clickHandler: 'onChangedPrepaymentStatus'
        }
      ],
      payStatusItems: [
        {text: 'Unpaid', value: 100},
        {text: 'Paid', value: 200},
        {text: 'Pending', value: 300},
        {text: 'Refund', value: 400},
        {text: 'Republish', value: 600},
        {text: 'Prepaid', value: 700},
        {text: 'Removed', value: 900},
        {text: 'Deleted', value: 500},
        {text: 'New', value: 1000}
      ],
      billingTypes: [
        {text: 'Guest Posting on', value: 'Guest Posting on'},
        {text: 'Link Insertion on', value: 'Link Insertion on'},
        {text: 'Home Page Link Insertion on', value: 'Home Page Link Insertion on'},
        {text: 'Article Writing and Publishing on', value: 'Article Writing and Publishing on'}
      ],
      backButton: {
        text:"Create a new order",
        icon: "mdi-keyboard-backspace",
        name: "SaleCounter",
        path: "/sale-counter",
      },
      bulkActions: [
        {
          icon: 'mdi-basket-remove',
          color: 'error',
          text: 'Remove',
          clickHandler: this.removeBulkPostItem
        },
        {
          icon: 'mdi-delete',
          color: 'error',
          text: 'Delete',
          clickHandler: this.deleteBulkItem
        },
      ]
    }
  },
  created() {
    this.currentPaging.startDate = moment().startOf('year').format('YYYY-MM-DD');
    this.currentPaging.endDate = moment().endOf('year').format('YYYY-MM-DD');
  },
  /*mounted() {
    this.$store.dispatch('load');
  }*/
  methods:{
    userHandler(selection){
      if(selection) {
        this.currentPaging.user = selection.id;
      } else {
        this.currentPaging.user = "";
      }

      this.loadData({isPagingReset: true});
    },
    orderFromHandler(selection) {
      if(selection) {
        this.currentPaging.orderFrom = selection.name;
      } else {
        this.currentPaging.orderFrom = "";
      }

      this.loadData({isPagingReset: true});
    },
    editItemNewPage(item) {
      this.$router.push({name: 'EditSaleCounter', path: `/edit-sale-counter/:id`, params: {id: item.id}})
    },
    async removePostItem(item, confirm = false) {
      this.confirmEvent = this["removePostItem"];
      this.confirmTxt = "Are you sure to removed this item?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      await this.handleDuplicateItem(this.actions.removePost, item);
    },
    async handleDuplicateItem(action, item) {
      this.$store
          .dispatch(action, item)
          .then(({ data: { data: {items} } }) => {
            if(items.length > 0){
              this.dialog = true;
              this.duplicatedLinks = items;
            } else {
              this.setSnackBar({ text: "Post removed successfully", type: "info" });
            }
          })
          .catch(({ response: { data } }) => {
            this.setSnackBar({
              text: data.message,
              type: "error",
            });
          })
          .finally(() => {
            this.loadData({ isPagingReset: true });
          });
    },
    async deleteWithoutItem(item, confirm = false) {
      this.confirmEvent = this["deleteWithoutItem"];
      this.confirmTxt = "Are you sure to delete this item without post?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;

      await this.handleItem(this.actions.deleteWithoutPost, item);
    },
    counterLinkHandler(item) {
      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.showPopup = true;
    },
    salesDetailsHandler(item) {
      this.$router.push({
        path: '/sale-counter-details/:id',
        name: 'SaleCounterDetails',
        params: {id: item.id}
      });
    },
    async republishHandler(item, confirm = false) {
      this.confirmEvent = this["republishHandler"];
      this.confirmTxt = "Are you sure to republished this item?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;

      await this.handleItem(this.actions.republish, item);
    },
    liveLinkReplacementHandler(item) {
      this.$router.push({
        name: "LiveLinkReplacement",
        path: "/live-link-replacement/:id",
        params: {id: item.id}
      })
    },
    async onChangedPrepaymentStatus(item, confirm=false) {
      this.confirmEvent = this["onChangedPrepaymentStatus"];
      this.confirmTxt = "Are you sure to change status prepaid to completed?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;

      await this.handleItem("salesCounter/changedPrepaymentStatus", item);
    },
    async deleteLinksItem(item, confirm = false) {
      this.confirmEvent = this["deleteLinksItem"];
      this.confirmTxt = "Are you sure to delete this item?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      const id = item.id;
      await this.handleLinksDestroy(this.actions.remove, {id});
    },
    async destroyLinksItem(item, confirm = false) {
      this.confirmEvent = this["destroyLinksItem"];
      this.confirmTxt = "Are you sure to destroy this item?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      const id = item.id;
      await this.handleLinksDestroy(this.actions.destroy, {id});
    },
    async handleLinksDestroy(actionHook, item, isPagingReset = true) {
      this.$store
          .dispatch(actionHook, item)
          .then(({ data: { data: {items} } }) => {
            if(items.length > 0){
              this.dialog = true;
              this.duplicatedLinks = items;
            } else {
              this.setSnackBar({ text: "Post deleted successfully", type: "info" });
            }
          })
          .catch(({ response: { data } }) => {
            this.setSnackBar({
              text: data.message,
              type: "error",
            });
          })
          .finally(() => {
            this.currentSelectedItem = null;
            this.loadData({ isPagingReset: true });
          });
    },
    closeDialogHandler() {
      this.dialog = false;
    },
    selectedItemHandler(items){
      this.selectedItems = items;
    },
    async removeBulkPostItem(item, confirm = false) {
      this.confirmEvent = this["removeBulkPostItem"];
      this.confirmTxt = "Are you sure to removed this selected bulk items?";
      if (!confirm) {
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      let ids = this.selectedItems.map((item) => item.id);
      await this.handleDuplicateItem(this.actions.removeBulkPostItem, {ids})
    },
    async deleteBulkItem(item, confirm = false) {
      this.confirmEvent = this["deleteBulkItem"];
      this.confirmTxt = "Are you sure to removed this selected bulk items?";
      if (!confirm) {
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      let ids = this.selectedItems.map((item) => item.id);
      await this.handleLinksDestroy(this.actions.deleteBulkPostItem, {ids})
    }
  }
}
</script>
